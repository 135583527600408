import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export const Clients = ({ clientList }) => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    setClients(clientList);
  }, [clientList]);
  return (
    <div className="clients-container">
      <div id="clients-header" className="header">
        - clients -
      </div>
      <div className="clients-message">
      * click on client to see work *
      </div>
      <div className="clients-list">
        {clients.map((client, index) => {
          return (
            <Link to={client.pathName} key={index} className="client-list-item">
              <div className="client-title">{client.name}</div>
              <div className="client-description">{client.roles}</div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
