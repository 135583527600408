import React from "react";

export const About = () => {
  return (
    <div className="about-container">
      <div className="about-bio">
      <div className="co-description"><i>~ A creative house based in New York & Los Angeles ~</i></div>
      </div>
    </div>
  );
};
