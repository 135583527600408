import React from "react";
import { Switch, Route } from "react-router-dom";
import { Landing } from "./components/Landing";
import { About } from "./components/About/About";
import { Clients } from "./components/Clients/Clients";
import { Contact } from "./components/Contact/Contact";
import { InProgressPage } from "./components/shared/InProgressPage";
import { SelectedClient } from "./components/Clients/SelectedClient";
import clientList from "./data/clients";
import "./styles/App.scss";

function App() {
  return (
    <div className="fade-in">
      <div className="box">
        <div className="box-inner">
          <Switch>
            <Route exact path="/">
              <Landing />
              <About />
              <Clients clientList={clientList} />
              <Contact />
            </Route>

            {clientList.map((client, index) => {
              return (
                <Route exact path={client.pathName} key={index}>
                  <SelectedClient client={client} />
                </Route>
              );
            })}
            <Route exact path="/in-progress">
              <InProgressPage />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default App;
